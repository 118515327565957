import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { EventCategory, HitType, useFsFlag } from "@flagship.io/react-sdk";
import { getDomainFromHostname } from "@ScreenerSetup/helpers";
import useScreenerStatus from "@ScreenerSetup/hooks/useScreenerStatus";
import { isEmpty } from "lodash-es";
import { destroyCookie } from "nookies";
import { breakpoints, Button } from "@boxt/design-system";

import { SCREENER_COOKIE_KEY } from "@Constants/screenerCookieKey";
import { BOILER_SUBSCRIPTION_URLS, SCREENING_URLS } from "@Collections/routes";
import type { ButterButtonCollectionItem, ButtonSkinItem } from "@Collections/types/butter/collectionItem";
import { sendToCs } from "@Lib/contentsquare";
import LifePopup from "@Components/pages/boilers/installation/LifePopup";
import { useAnalytics } from "@Hooks/useAnalytics";
import useBoxtFlagship from "@Hooks/useBoxtFlagship";
import useMedia from "@Hooks/useMedia";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import FinishQuote, { getButtonSize } from "./components/FinishQuote/FinishQuote";
import { Container } from "./styles";

export type Props = {
  screenerVariant?: ScreenerVariant;
  startQuoteButtonText?: string;
  finishQuoteButtonText?: string;
  finishQuoteButtonTheme?: ButterButtonCollectionItem;
  finishQuoteButtonSkin?: ButtonSkinItem;
  startQuoteButtonSkin?: ButtonSkinItem;
  startQuoteButtonTheme?: ButterButtonCollectionItem;
  mainStartQuoteButtonSkin?: ButtonSkinItem;
  mainStartQuoteButtonText?: string;
  mainStartQuoteButtonTheme?: ButterButtonCollectionItem;
};

const ScreenerPostcodeEntry = ({
  finishQuoteButtonSkin,
  finishQuoteButtonText,
  finishQuoteButtonTheme,
  screenerVariant,
  startQuoteButtonSkin,
  startQuoteButtonText,
  startQuoteButtonTheme,
  mainStartQuoteButtonSkin,
  mainStartQuoteButtonText,
  mainStartQuoteButtonTheme,
}: Props) => {
  const [isLoadingScreener, setIsLoadingScreener] = useState(false);
  const [isMovingToScreenerPage, setIsMovingToScreenerPage] = useState(false);
  const isLifeModalFlagEnabled = useFsFlag("nextjs-blp-life-modal", false).getValue();
  const isLifeModalScreenerRedirectFlagEnabled = useFsFlag(
    "nextjs-blp-life-modal-straight-to-screener",
    false,
  ).getValue();
  const segmentAnalytics = useAnalytics();
  const { push, query } = useRouter();
  const isMobile = useMedia(`(max-width: ${breakpoints.md.width})`);
  const isDesktop = useMedia(`(min-width: ${breakpoints.lg.width})`);
  const { t } = useTranslation("common");

  const screenerState = useScreenerStatus([{ variant: screenerVariant as ScreenerVariant, version: "base" }])[
    screenerVariant as ScreenerVariant
  ];

  const { isScreenerStarted } = screenerState || {};

  const [isLifeModalOpen, setIsLifeModalOpen] = useState(false);

  const onLifePopupClose = () => {
    setIsMovingToScreenerPage(false);
    setIsLifeModalOpen(false);
  };

  const { fireFlagshipEvent } = useBoxtFlagship();
  const fireSegmentClickEvent = (ctaName: string, clickText: string) => {
    segmentAnalytics?.track("cta_clicked", {
      category: "boiler_install",
      click_text: clickText,
      cta_name: ctaName,
    });
  };

  const skeletonHeight = isMobile ? 60 : 92;

  const isEdfUrl = query?.utm_source?.includes("edf");

  const handleBoilerInstallationStart = () => {
    if (!isEdfUrl) {
      sendToCs("AB_ABT_FS_nextjs-blp-life-modal", isLifeModalFlagEnabled ? "New variation" : "Original");
      sendToCs(
        "AB_ABT_FS_nextjs_blp_life_modal_straight_to_screener",
        isLifeModalScreenerRedirectFlagEnabled ? "New variation" : "Original",
      );
      if (isLifeModalFlagEnabled) {
        return setIsLifeModalOpen(true);
      }
    }
    goToScreener();
  };

  const handleOnStartQuote = async () => {
    destroyCookie(null, `${SCREENER_COOKIE_KEY}:${screenerVariant}`, {
      domain: getDomainFromHostname(window.location.hostname),
      path: "/",
    });
    setIsMovingToScreenerPage(true);
    if (screenerVariant === ScreenerVariant.BoilerInstallation) {
      handleBoilerInstallationStart();
    } else {
      goToScreener();
    }
  };

  const handleClickForFlagship = (name: string) =>
    fireFlagshipEvent({
      action: name,
      category: EventCategory.ACTION_TRACKING,
      type: HitType.EVENT,
    });

  const handleOnFinishQuote = () => {
    setIsLoadingScreener(true);

    goToScreener();
  };

  const handleOnClickRental = () => {
    setIsLoadingScreener(true);
    fireSegmentClickEvent("boiler_install_blp_purchase_life_split_modal", "BOXT Life - Get an all-in-one boiler plan");
    handleClickForFlagship("Modal life cta click");
    push({
      pathname: isLifeModalScreenerRedirectFlagEnabled
        ? BOILER_SUBSCRIPTION_URLS.SCREENING
        : BOILER_SUBSCRIPTION_URLS.LANDING,
      query: {
        ...query,
      },
    });
  };

  const handleOnClickPurchase = () => {
    setIsLoadingScreener(true);
    fireSegmentClickEvent("boiler_install_blp_purchase_life_split_modal", "Buy a boiler - Get a fixed price");
    if (isLifeModalFlagEnabled) {
      handleClickForFlagship("Modal install cta click");
    }
    goToScreener();
  };

  const goToScreener = () => {
    delete query.path;
    // quote_expired will be used to display Expired quote Modal
    // it is set in customer-next/src/helpers/middlewares/quotes/[id]/activate.ts
    // after cleaning screening cookie
    delete query.quote_expired;
    push({ pathname: SCREENING_URLS[screenerVariant as ScreenerVariant].start, query });
  };

  return (
    <>
      <Container>
        {isMovingToScreenerPage ? (
          <Skeleton containerTestId="skeleton-loader" height={skeletonHeight} />
        ) : (
          <>
            {isScreenerStarted ? (
              <FinishQuote
                finishQuoteButtonSkin={finishQuoteButtonSkin}
                finishQuoteButtonText={finishQuoteButtonText}
                finishQuoteButtonTheme={finishQuoteButtonTheme}
                isLoading={isLoadingScreener}
                onFinishQuote={handleOnFinishQuote}
                onStartQuote={handleOnStartQuote}
                startQuoteButtonSkin={startQuoteButtonSkin}
                startQuoteButtonText={startQuoteButtonText}
                startQuoteButtonTheme={startQuoteButtonTheme}
              />
            ) : (
              <Button
                boxtTheme={isEmpty(mainStartQuoteButtonTheme) ? "jade" : mainStartQuoteButtonTheme?.theme}
                data-testid="start-quote-button"
                isFullWidth={isMobile}
                onClick={handleOnStartQuote}
                size={getButtonSize(isDesktop, isMobile)}
                skin={isEmpty(mainStartQuoteButtonSkin) ? "primary" : mainStartQuoteButtonSkin?.name}
              >
                {mainStartQuoteButtonText ? mainStartQuoteButtonText : t("postcode.submit")}
              </Button>
            )}
          </>
        )}
      </Container>
      {isLifeModalOpen && (
        <LifePopup
          isLoadingScreener={isLoadingScreener}
          onClickPurchase={handleOnClickPurchase}
          onClickRental={handleOnClickRental}
          onDismiss={onLifePopupClose}
        />
      )}
    </>
  );
};

export default ScreenerPostcodeEntry;
