import { isFinite, omit } from "lodash-es";
import useSWR from "swr";

import keepPreviousData from "@Helpers/keepPreviousData/keepPreviousData";
import type { Products } from "@Collections/products";
import { API_URLS } from "@Collections/routes";
import { fetcher } from "@DataAccess/local";
import type { FinancePlan, SenaFinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import useHasFinance from "@Hooks/useHasFinance";

type Props = {
  productType: Products | undefined;
  deposit?: number;
  purchasePrice: number | undefined;
  hasSenaPlans?: boolean;
};

type ReturnType = {
  isLoading: boolean;
  isValidating: boolean;
  financePlans: FinancePlan[];
  hasError: Boolean;
};
export const decoratePlans = (data: { plans: SenaFinancePlan[] }): FinancePlan[] =>
  data.plans?.map((plan) => ({
    ...omit(plan, "loanCalculation"),
    calculations: plan.loanCalculation,
  }));

const useFetchSenaFinancePlans = ({ deposit, productType, purchasePrice, hasSenaPlans }: Props): ReturnType => {
  const { hasFinance } = useHasFinance({ productType });

  const depositAmount = isFinite(deposit) ? deposit : Math.floor(Number(purchasePrice) / 2);
  const { data, error, isValidating } = useSWR<{ plans: SenaFinancePlan[] }>(
    hasFinance && isFinite(depositAmount) && isFinite(purchasePrice) && hasSenaPlans
      ? [API_URLS.FINANCE.SENA.PLANS, { deposit: depositAmount, purchase_price: purchasePrice }]
      : null,
    ([url, params]) => fetcher(url, params),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      use: [keepPreviousData],
    },
  );

  return {
    financePlans: data ? decoratePlans(data) : [],
    hasError: Boolean(error),
    isLoading: hasFinance && !data && !error,
    isValidating,
  };
};

export default useFetchSenaFinancePlans;
