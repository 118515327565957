import { useRouter } from "next/router";
import { getDomainFromHostname } from "@ScreenerSetup/helpers";
import { destroyCookie, parseCookies } from "nookies";
import { useSWRConfig } from "swr";

import { SCREENER_COOKIE_KEY } from "@Constants/screenerCookieKey";
import { API_URLS } from "@Collections/routes";

const cleanKey = "clean-data";
const useCleanData = () => {
  const { query, push, pathname } = useRouter();
  const { mutate } = useSWRConfig();
  const hasCleanParam = query[cleanKey] === "true";

  const cleanPromoData = () => {
    destroyCookie(null, "next-promo", { path: "/" });
  };

  const removeCleanDataQueryParam = () => {
    delete query[cleanKey];

    push({ pathname, query }, undefined, { shallow: true });
  };

  const cleanScreeningData = async () => {
    const cookies = parseCookies();
    const screeningVariants = Object.entries(cookies)
      .map(([key]) => key)
      .filter((key) => key.includes(SCREENER_COOKIE_KEY));

    screeningVariants.forEach((variant) => {
      destroyCookie(null, variant, { domain: getDomainFromHostname(window.location.hostname), path: "/" });
    });
    await mutate(API_URLS.SCREENING_STATUS, { isScreenerComplete: false, isScreenerStarted: false });
  };

  const cleanAll = async () => {
    cleanPromoData();
    await cleanScreeningData();
  };

  return {
    cleanAll,
    cleanPromoData,
    cleanScreeningData,
    hasCleanParam,
    removeCleanDataQueryParam,
  };
};

export default useCleanData;
