import useTranslation from "next-translate/useTranslation";
import { Button, Spacing } from "@boxt/design-system";

import { FinancePaymentTypes } from "@Components/FinanceCalculator/types";

export type Props = {
  ctaText?: string;
  isPdpRedirectEnabled?: boolean;
  onAddToBasket?: () => void;
  onCancel?: () => void;
  onProductDetailsClick?: () => void;
  onUpdatePlan?: () => void;
  paymentType: FinancePaymentTypes;
  isManufacturerPdp?: boolean;
};

const Actions = ({
  ctaText,
  isPdpRedirectEnabled,
  onAddToBasket,
  onCancel,
  onProductDetailsClick,
  onUpdatePlan,
  paymentType,
  isManufacturerPdp = false,
}: Props) => {
  const { t } = useTranslation("common");
  const { t: tManufacturerPDP } = useTranslation("boilers/manufacturer-pdp");
  const isLife = paymentType === FinancePaymentTypes.Life;

  return (
    <>
      {onAddToBasket ? (
        <Spacing mv={2}>
          <Button boxtTheme={isLife ? "coral" : "jade"} isFullWidth onClick={onAddToBasket} size="large">
            {isLife
              ? t("calculator.choose")
              : isManufacturerPdp
                ? tManufacturerPDP("card.get-fixed-price")
                : t("calculator.add-to-basket")}
          </Button>
        </Spacing>
      ) : null}
      {onProductDetailsClick && ctaText ? (
        <Button
          boxtTheme={isPdpRedirectEnabled ? "jade" : "coal"}
          isFullWidth
          onClick={onProductDetailsClick}
          size="large"
          skin={isPdpRedirectEnabled ? "primary" : "secondary"}
        >
          {ctaText}
        </Button>
      ) : null}
      {onUpdatePlan ? (
        <Spacing mv={2}>
          <Button boxtTheme="jade" disabled isFullWidth onClick={onUpdatePlan} size="large" skin="primary">
            {t("calculator.update-plan")}
          </Button>
        </Spacing>
      ) : null}
      {onCancel ? (
        <Button boxtTheme="jade" isFullWidth onClick={onCancel} size="large" skin="secondary">
          {t("calculator.cancel")}
        </Button>
      ) : null}
    </>
  );
};

export default Actions;
