import type { ElementType } from "react";
import type { FontTheme, Weight } from "@boxt/design-system";
import { H1, H2, H3, H4, H5, Paragraph } from "@boxt/design-system";

import ParsedDiv from "./ParsedDiv";
import ParsedImage from "./ParsedImage";
import ParsedLi from "./ParsedLi";
import ParsedLink from "./ParsedLink";
import ParsedOl from "./ParsedOl";

export type IHTMLParserComponentMapConfigItem =
  | IHTMLParserComponentMapConfigRenderedAs
  | IHTMParserComponentMapConfigLStyledAs;

type IHTMLParserComponentMapConfigItemBase = {
  align?: string;
  theme?: FontTheme;
  weight?: Weight;
};

type IHTMLParserComponentMapConfigRenderedAs = IHTMLParserComponentMapConfigItemBase & {
  renderedAs: ElementType;
  styledAs?: ElementType;
};

type IHTMParserComponentMapConfigLStyledAs = IHTMLParserComponentMapConfigItemBase & {
  renderedAs?: ElementType;
  styledAs: ElementType;
};

export type IHTMLParserComponentMapConfig = Record<string, IHTMLParserComponentMapConfigItem>;

const DEFAULT_COMPONENT_MAP: IHTMLParserComponentMapConfig = {
  a: {
    renderedAs: ParsedLink,
  },
  div: {
    renderedAs: ParsedDiv,
  },
  h1: {
    renderedAs: H1,
  },
  h2: {
    renderedAs: H2,
  },
  h3: {
    renderedAs: H3,
  },
  h4: {
    renderedAs: H4,
  },
  h5: {
    renderedAs: H5,
  },
  h6: {
    renderedAs: H5,
  },
  img: {
    renderedAs: ParsedImage,
  },
  li: {
    renderedAs: ParsedLi,
  },
  ol: {
    renderedAs: ParsedOl,
  },
  p: {
    renderedAs: Paragraph,
  },
};

export { DEFAULT_COMPONENT_MAP };
