export enum PageType {
  PLP = "plp",
  PDP = "pdp",
  Finance = "finance",
  RetailFinance = "retail-finance",
}

export enum FinancePaymentTypes {
  BNPL = "bnpl",
  Life = "life",
  Default = "default",
}
