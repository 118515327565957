import styled, { css } from "styled-components";
import { breakpoint, colors, Grid, H4, rem } from "@boxt/design-system";

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  padding: ${rem(42)} ${rem(32)} ${rem(32)} ${rem(32)};

  ${breakpoint("lg")} {
    align-items: center;
    justify-items: center;
    text-align: center;
  }
`;

export const StyledGridCol = styled(Grid.Col)`
  text-align: center;
  padding: ${rem(24)} ${rem(24)} ${rem(24)} 0;

  ${breakpoint("lg")} {
    padding-left: ${rem(24)};
  }
`;

export const StyledItemWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: flex-start;
  flex-direction: row;

  ${breakpoint("lg")} {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledH4 = styled(H4)`
  font-weight: 400 !important;
`;

export const StyledContent = styled.div`
  text-align: left;

  ${breakpoint("lg")} {
    text-align: center;
  }
`;

export const StyledItemsGridRow = styled(Grid.Row)`
  justify-content: center;
  padding: 0 ${rem(32)} ${rem(32)} ${rem(32)};
`;

export const StyledGrid = styled(Grid)<{
  $backgroundColor: string;
  $shouldUseLightTextColor: boolean;
}>`
  margin: ${rem(32)} 0;

  ${({ $backgroundColor }) => css`
    background-color: ${$backgroundColor};
  `};

  ${({ $shouldUseLightTextColor }) => css`
    h3,
    h4 {
      color: ${$shouldUseLightTextColor ? colors.white : "inherit"};
    }
    p {
      color: ${$shouldUseLightTextColor ? colors.platinum.lighter : "inherit"};
    }
  `};
`;
