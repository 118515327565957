import { useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { useFsFlag } from "@flagship.io/react-sdk";
import { isEmpty } from "lodash-es";
import { Grid, LoadingOverlay } from "@boxt/design-system";

import { Products } from "@Collections/products";
import { LeftColumnDiv, RightColumnDiv } from "@Components/pages/finance/styles";
import type { ButterFinanceCalculatorTabFields } from "@Components/pages/finance/types";
import useFinance from "@Hooks/useFinance";

import Actions from "./components/Actions";
import LeftColumnContent from "./components/LeftColumnContent";
import PaymentDetails from "./components/PaymentDetails";
import RightColumnContent from "./components/RightColumnContent";
import { DEFAULT_MIN_PURCHASE_PRICE, DEFAULT_PURCHASE_PRICE, isValidPrice } from "./helpers";
import { CalculatingContainer, ContentWrapper, LoadingWrapper } from "./styles";
import { FinancePaymentTypes, PageType } from "./types";

type Props = {
  content: ButterFinanceCalculatorTabFields;
  fixedAmount?: number;
  onAddToBasket?: () => void;
  onCancel?: () => void;
  zeroPercentFinanceThirtySix?: boolean;
  zeroPercentFinanceFortyEight?: boolean;
  includeScrollDelay?: boolean;
  onProductDetailsClick?: () => void;
  onUpdatePlan?: () => void;
  isBnpl?: boolean;
  isPayMonthly?: boolean;
  pageType?: PageType;
  isManufacturerPdp?: boolean;
};

const FinanceCalculator = ({
  content,
  fixedAmount,
  onAddToBasket,
  onCancel,
  onProductDetailsClick,
  onUpdatePlan,
  includeScrollDelay = false,
  zeroPercentFinanceThirtySix = true,
  zeroPercentFinanceFortyEight = true,
  isBnpl = false,
  isPayMonthly = true,
  pageType = PageType.Finance,
  isManufacturerPdp = false,
}: Props) => {
  const { t } = useTranslation("common");
  const { calc_tab_left_column_default_value_displayed, calc_tab_left_column_minimum_value_allowed, product_type } =
    content;
  const defaultPurchasePrice = calc_tab_left_column_default_value_displayed || DEFAULT_PURCHASE_PRICE;
  const minPurchasePrice = calc_tab_left_column_minimum_value_allowed || DEFAULT_MIN_PURCHASE_PRICE;
  const productType = isEmpty(product_type) ? Products.Boiler : (product_type?.product_type as Products);

  const startingPrice = fixedAmount || defaultPurchasePrice;

  const [purchasePrice, setPurchasePrice] = useState(startingPrice);
  const [priceOfProductInputValue, setPriceOfProductInputValue] = useState(startingPrice);
  const [selectedPlanId, setSelectedPlanId] = useState<string>();
  const [depositAmount, setDepositAmount] = useState(startingPrice / 2);

  const isRetailFinance = pageType === PageType.RetailFinance;
  const { activePlan, financePlans, isCalculating } = useFinance({
    deposit: Math.floor(depositAmount),
    hasSenaPlans: isRetailFinance,
    isBnpl,
    isPayMonthly,
    isRetailFinance,
    productType,
    purchasePrice,
    selectedPlanId,
    zeroPercentFinanceFortyEight,
    zeroPercentFinanceThirtySix,
  });

  const isPdpRedirectEnabled = useFsFlag("pdp-is-mandatory-step-v2", false).getValue();

  const showAddToBasketCta =
    (onAddToBasket && pageType === PageType.PDP) ||
    (onAddToBasket && pageType === PageType.PLP && !isPdpRedirectEnabled);

  const ctaText =
    isPdpRedirectEnabled && pageType !== PageType.Finance
      ? t("calculator.choose")
      : t("calculator.view-product-detail");

  const handleOnPriceChange = (value: number) => {
    setPriceOfProductInputValue(value);

    if (isValidPrice(minPurchasePrice, value)) {
      setDepositAmount(value * (depositAmount / purchasePrice));
      setPurchasePrice(value);
    }
  };

  const handleOnPriceBlur = (value: number) => {
    if (!isValidPrice(minPurchasePrice, value)) {
      setDepositAmount(minPurchasePrice * (depositAmount / purchasePrice));
      setPurchasePrice(minPurchasePrice);
      setPriceOfProductInputValue(minPurchasePrice);
    }
  };

  const paymentType = isBnpl ? FinancePaymentTypes.BNPL : FinancePaymentTypes.Default;

  const actions = (
    <Actions
      ctaText={ctaText}
      isManufacturerPdp={isManufacturerPdp}
      isPdpRedirectEnabled={isPdpRedirectEnabled}
      onAddToBasket={showAddToBasketCta ? onAddToBasket : undefined}
      onCancel={onCancel}
      onProductDetailsClick={onProductDetailsClick}
      onUpdatePlan={onUpdatePlan}
      paymentType={paymentType}
    />
  );

  return (
    <>
      {!activePlan ? (
        <Grid.Col>
          <LoadingWrapper data-testid="finance-loader">
            <LoadingOverlay />
          </LoadingWrapper>
        </Grid.Col>
      ) : (
        <>
          <ContentWrapper>
            <Grid.Col as={LeftColumnDiv} lg={9}>
              <LeftColumnContent
                content={content}
                depositGuide={{
                  maxDepositPercentage: activePlan?.maxDepositPercentage || 0,
                  minDepositPercentage: activePlan?.minDepositPercentage || 0,
                }}
                depositValue={depositAmount}
                financePlans={financePlans}
                hasFixedAmount={Boolean(fixedAmount)}
                hasHelperText={Boolean(fixedAmount) && pageType !== PageType.RetailFinance}
                includeScrollDelay={includeScrollDelay}
                onMonthlyPaymentChange={setSelectedPlanId}
                onPriceBlur={handleOnPriceBlur}
                onPriceChange={handleOnPriceChange}
                priceOfProduct={priceOfProductInputValue}
                purchasePrice={purchasePrice}
                selectedPlanId={activePlan?.id}
                setDepositAmount={setDepositAmount}
                zeroPercentFinanceFortyEight={zeroPercentFinanceFortyEight}
                zeroPercentFinanceThirtySix={zeroPercentFinanceThirtySix}
              />
            </Grid.Col>
            <Grid.Col as={RightColumnDiv} lg={7} md={0} sm={0}>
              {isCalculating && (
                <CalculatingContainer data-testid="calculating-loader">
                  <LoadingOverlay />
                </CalculatingContainer>
              )}
              <RightColumnContent
                actions={actions}
                activePlan={activePlan}
                content={content}
                hasFixedAmount={Boolean(fixedAmount)}
                isBnpl={isBnpl}
              />
            </Grid.Col>
          </ContentWrapper>
          <PaymentDetails
            actions={actions}
            activePlan={activePlan}
            content={content}
            hasFixedAmount={Boolean(fixedAmount)}
            paymentType={paymentType}
          />
        </>
      )}
    </>
  );
};

export default FinanceCalculator;
