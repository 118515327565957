import { getDomainFromHostname } from "@ScreenerSetup/helpers";
import { isEmpty } from "lodash-es";
import { destroyCookie } from "nookies";
import { H4, Paragraph, Spacing } from "@boxt/design-system";

import { SCREENER_COOKIE_KEY } from "@Constants/screenerCookieKey";
import type { ButterProductTileCollectionItem } from "@Collections/types/butter/collectionItem";
import Image from "@Components/Image";

import Chevron from "../Chevron";

import { CardContainer, ChevronContainer, ImageWrapper, InfoContentWrapper, TextContentWrapper } from "./styles";

export type Props = {
  cardConfig: ButterProductTileCollectionItem;
  cardIndex: number;
  lastCard?: boolean;
  firstCard?: boolean;
  isBottomSheet: boolean;
};

const Card = ({ cardConfig, cardIndex, lastCard = false, firstCard = false, isBottomSheet }: Props) => {
  const { image, description, title, link, onClick, screener_variant } = cardConfig;

  const isNewQuoteCard = Boolean(onClick);

  const handleOnClick = () => {
    if (isNewQuoteCard) {
      onClick?.();
    }
    if (!isEmpty(screener_variant)) {
      destroyCookie(null, `${SCREENER_COOKIE_KEY}:${screener_variant?.slug}`, {
        domain: getDomainFromHostname(window.location.hostname),
        path: "/",
      });
    }
  };

  return (
    <CardContainer
      aria-label={`product-tile-item-${cardIndex}`}
      {...(!isNewQuoteCard && { href: link })}
      $firstItem={firstCard}
      $isBottomSheet={isBottomSheet}
      $lastItem={lastCard}
      data-testid="card-container"
      onClick={handleOnClick}
    >
      <ImageWrapper $isBottomSheet={isBottomSheet}>
        <Image alt={`image-${title}`} layout="fill" objectFit="cover" src={image} />
      </ImageWrapper>
      <Spacing mb={4} />
      <InfoContentWrapper>
        <TextContentWrapper $isBottomSheet={isBottomSheet} $isNewQuoteCard={isNewQuoteCard}>
          <H4 boxtTheme="slate">{title}</H4>
          <Paragraph boxtTheme="slate" top={3}>
            {description}
          </Paragraph>
        </TextContentWrapper>
        <ChevronContainer $isBottomSheet={isBottomSheet}>
          <Chevron />
        </ChevronContainer>
      </InfoContentWrapper>
    </CardContainer>
  );
};

export default Card;
