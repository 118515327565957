export const sendToCs = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    window._uxa = window._uxa || [];

    window._uxa.push([
      "trackDynamicVariable",
      {
        key,
        value,
      },
    ]);
  }
};
