import fontColorContrast from "font-color-contrast";
import { colors, Grid, H3, Paragraph, Spacing } from "@boxt/design-system";

import Image from "@Components/Image";

import {
  StyledContent,
  StyledGrid,
  StyledGridCol,
  StyledH4,
  StyledHeader,
  StyledItemsGridRow,
  StyledItemWrapper,
} from "./styles";
import type { ButterIconGridFields } from "./types";

type Props = {
  fields: ButterIconGridFields;
};

const IconGrid = ({ fields }: Props) => {
  const hasCustomBackground = Boolean(fields?.background_theme);
  const backgroundColor = hasCustomBackground ? fields?.background_theme : colors.white;
  const shouldUseLightTextColor = fontColorContrast(backgroundColor) === "#ffffff";

  const calculateGridColumns = () => {
    const noOfItems = fields?.items.length;

    switch (noOfItems) {
      case 2:
      case 4:
        return { offset: 0, span: 8 };
      case 3:
      case 5:
      case 6:
        return { offset: -1, span: 5 };
      default:
        return { offset: 0, span: 16 };
    }
  };

  return (
    <StyledGrid $backgroundColor={backgroundColor} $shouldUseLightTextColor={shouldUseLightTextColor} align="center">
      <StyledHeader>
        <Spacing mb={1}>
          <Grid.Row>
            <H3>{fields?.title}</H3>
          </Grid.Row>
        </Spacing>
        <Grid.Row>
          <Paragraph size="medium">{fields?.subtitle}</Paragraph>
        </Grid.Row>
      </StyledHeader>

      <StyledItemsGridRow>
        {fields?.items.map(({ icon, title, description }) => (
          <StyledGridCol data-testid="icon-grid-item" key={title} lg={calculateGridColumns()} md={16}>
            <StyledItemWrapper>
              <Spacing mr={{ lg: 0, md: 2, sm: 2 }}>
                <Image alt={title} height={60} layout="fixed" src={icon} width={60} />
              </Spacing>
              <StyledContent>
                <Spacing mb={1} mt={{ lg: 1, md: 0, sm: 0 }}>
                  <StyledH4>{title}</StyledH4>
                </Spacing>
                <Paragraph>{description}</Paragraph>
              </StyledContent>
            </StyledItemWrapper>
          </StyledGridCol>
        ))}
      </StyledItemsGridRow>
    </StyledGrid>
  );
};

export default IconGrid;
