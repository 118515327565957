import React from "react";
import type { CSSObject } from "styled-components";
import type { DOMNode, Element } from "html-react-parser";
import { domToReact } from "html-react-parser";
import type { FontTheme } from "@boxt/design-system";
import { Paragraph } from "@boxt/design-system";

import type { FONT_COLOR } from "@Collections/themeColors";

import type { IHTMLParserComponentMapConfig } from "../config";
import { getDOMParserOptions } from "../helpers";

import { StyledOl } from "./styles";

type Props = {
  idAttribute: string;
  $domNode: Element;
  theme: FontTheme;
  $styleObject: CSSObject;
  $componentMap: IHTMLParserComponentMapConfig;
  $contentTheme: keyof typeof FONT_COLOR;
  $headingTheme: keyof typeof FONT_COLOR;
  start?: string;
  type?: string;
};

const ParsedOl = ({ $domNode, $styleObject, $componentMap, $contentTheme, $headingTheme, start, type }: Props) => {
  const startNumber = start ? Number(start) : 1;
  const typeString = type || "";

  return (
    <StyledOl $styleObject={$styleObject} start={startNumber} type={typeString}>
      <Paragraph as="span">
        {domToReact(
          $domNode.children as DOMNode[],
          getDOMParserOptions({
            componentMap: $componentMap,
            contentTheme: $contentTheme,
            headingTheme: $headingTheme,
          }),
        )}
      </Paragraph>
    </StyledOl>
  );
};

export default ParsedOl;
