import type { GetStaticPaths } from "next";
import getConfig from "next/config";

import { fetchAllButterPages, getButterContentPage } from "@DataAccess/remote/content";
import Bugsnag from "@Lib/bugsnag";
import type { Props } from "@Components/pages/contentPage";
import ContentComponent from "@Components/pages/contentPage";

const ContentPage = (props: Props) => {
  return <ContentComponent {...props} />;
};

export const getStaticPaths: GetStaticPaths = async () => {
  if (process.env.CI) {
    return {
      fallback: false,
      paths: [],
    };
  } else {
    const allPages = await fetchAllButterPages();

    const paths = (allPages || []).map(({ fields, slug }: { fields: Record<string, any>; slug: string }) => ({
      params: {
        path: [...fields.prepend_path_value.split("/"), slug].filter((path) => path),
      },
    }));

    return {
      fallback: false,
      paths,
    };
  }
};

export const getStaticProps = async ({ params: { path } }) => {
  const slug = path[path.length - 1];
  // nextjs version - no
  // caught in next.config rewrites section
  // if redirected
  const destination = `/${path.join("/")}?vnxt=n`;

  try {
    const page = await getButterContentPage(slug, { levels: 3 });

    return {
      props: {
        destination,
        fields: page?.fields,
        slug,
      },
      revalidate: 1000,
    };
  } catch (error) {
    // redirect cannot be returned during build phase (only during server one)
    if (getConfig()?.serverRuntimeConfig?.BUILD_PHASE) {
      // We are checking 403 because there are multiple unpublished pages with same slug. ButterCMS returns 403 for this error.
      if (error?.response?.status === 404 || error?.response?.status === 403) {
        return {
          props: {
            destination,
            // we want to redirect to rails when throws,
            // but it is impossible to do it on server-side for getStaticProps during build phase
            // however the result of page pre-rendering will be used for 1st render after rebuild,
            // so we will do redirect on client-side in this case.
            redirectOnClient: true,
          },
          // need to revalidate, since otherwise this behaviour will be cached
          // until next rebuild for the particular page
          revalidate: 1,
        };
      } else {
        Bugsnag.notify(error);
        throw new Error("Build failed due to ButterCMS error");
      }
    } else {
      return {
        redirect: {
          destination,
          permanent: false,
        },
        // need to revalidate, since otherwise this behaviour will be cached
        // until next rebuild for the particular page
        revalidate: 1000,
      };
    }
  }
};

export default ContentPage;

ContentPage.layoutConfig = {
  hasCustomNavigation: true,
};
