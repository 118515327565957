"use client";

import type { CSSObject } from "styled-components";
import styled, { css } from "styled-components";

export const StyledOl = styled.ol.attrs({ id: "parsed-butter-ol" })<{
  $styleObject: CSSObject;
  start: number;
  type: string;
}>`
  &#parsed-butter-ol {
    ${({ $styleObject }) => css`
      ${$styleObject};
    `}

    counter-reset: list-counter ${({ start }) => start - 1} !important;
    list-style: ${({ type }) => (type ? `lower-alpha` : `decimal`)};
    ${({ type }) => type && `margin-left: 3rem;`}

    & > li {
      counter-increment: list-counter !important;
    }

    & > span > li::before {
      content: counter(list-counter) ". " !important;
      display: none !important;
    }
  }
`;
