import type { ReactNode } from "react";
import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, H3, Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter from "@Helpers/currency";
import { CUSTOMER_URLS } from "@Collections/routes";
import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import type { ButterFinanceCalculatorTabFields } from "@Components/pages/finance/types";

import { RightColumnContainer, TextWrapper } from "./styles";

export type Props = {
  actions: ReactNode;
  activePlan: FinancePlan;
  content: ButterFinanceCalculatorTabFields;
  hasFixedAmount: boolean;
  isBnpl: boolean;
};

const RightColumnContent = ({ actions, content, activePlan, hasFixedAmount, isBnpl }: Props) => {
  const { t } = useTranslation("common");

  const {
    calc_tab_cta_button_link: ctaButtonLink,
    calc_tab_cta_button_text: ctaButtonText,
    calc_tab_cta_button_theme: ctaButtonTheme,
  } = content;

  return (
    <RightColumnContainer>
      <TextWrapper>
        <Paragraph boxtTheme="slate">
          <Trans
            components={{ strong: <strong /> }}
            i18nKey={
              isBnpl ? "common:calculator.deferred-monthly-payments-of" : "common:calculator.monthly-payments-of"
            }
            values={{ deferralPeriod: activePlan.deferredPeriod, months: activePlan.term }}
          />
        </Paragraph>
        <H3 boxtTheme="coalDark" weight="bold">
          {currencyFormatter(activePlan.calculations.monthlyPayment)}
        </H3>
      </TextWrapper>
      <Spacing mb={2}>
        {hasFixedAmount ? (
          actions
        ) : (
          <Link href={ctaButtonLink || CUSTOMER_URLS.BOILER} legacyBehavior passHref>
            <Button boxtTheme={ctaButtonTheme?.theme || "jade"} isFullWidth size="large">
              {ctaButtonText || t("calculator.fixed-price")}
            </Button>
          </Link>
        )}
      </Spacing>
    </RightColumnContainer>
  );
};

export default RightColumnContent;
