import type { Products } from "@Collections/products";
import type { Customer } from "@Collections/types/customer";
import type { AddressHistoryAddress, CheckoutFields } from "@StateMachines/Checkout/constants";
import type { ProductCheckoutContext, RepairCheckoutContext } from "@StateMachines/Checkout/types";
import type { CalendarTheme } from "@Components/Calendar/types";
import type { Basket, ScreeningQuery } from "@Components/Checkout";
import type { SolarBasketData } from "@Components/SolarConfigurator/types";
import type { ScreenerVariant } from "@Pages/api/_next/screeners/types";

import type { SolarEnquiryMachineData } from "../helpers";

import type {
  Address,
  BillingAddress,
  CheckboxName,
  CustomerDetail,
  CustomerType,
  DirectDebit,
  DirectDebitCheckbox,
  FinancialSituationChanged,
} from "./constants";

export type CheckoutFormValues = {
  [CheckoutFields.SelectedDate]?: string | null;
  [Address.Line1]: string;
  [Address.Line2]: string | null;
  [Address.Line3]: string | null;
  [Address.City]: string;
  [Address.County]: string | null;
  [Address.Postcode]: string | undefined;
  [Address.Note]?: string;
  [CustomerDetail.FirstName]: string | undefined;
  [CustomerDetail.Surname]: string;
  [CustomerDetail.Email]: string;
  [CustomerDetail.Phone]: string;
  [CustomerDetail.Title]?: string;
  [CustomerDetail.HowFirstHearAboutBoxt]?: string | null;
  [CustomerDetail.CustomerType]?: CustomerType | null;
  [BillingAddress.Line1]?: string;
  [BillingAddress.Line2]?: string;
  [BillingAddress.Line3]?: string;
  [BillingAddress.City]?: string;
  [BillingAddress.County]?: string;
  [BillingAddress.Postcode]?: string;
  [CheckboxName.AcceptTerms]?: boolean;
  [CheckboxName.AcceptMailing]?: boolean;
};

export type SolarEnquiryFormValues = {
  basket?: SolarBasketData;
  jobLength: number;
  selectedDate: null | string;
  checkoutInstallerId: null | string;
  preferredPaymentOptions: string[];
  [CheckboxName.AcceptTerms]?: boolean;
  [CheckboxName.AcceptMailing]?: boolean;
} & SolarInstallationAddressValues &
  CustomerDetailsValues;

type SolarInstallationAddressValues = {
  [Address.Line1]: string;
  [Address.Line2]: string;
  [Address.Line3]: string;
  [Address.City]: string;
  [Address.County]: string;
  [Address.Postcode]: string;
  [CustomerDetail.CustomerType]: CustomerType | null;
  [BillingAddress.Line1]?: string;
  [BillingAddress.Line2]?: string;
  [BillingAddress.Line3]?: string;
  [BillingAddress.City]?: string;
  [BillingAddress.County]?: string;
  [BillingAddress.Postcode]?: string;
};

export type PaymentFormValues = {
  [CheckboxName.AcceptTerms]: boolean;
  [CheckboxName.AcceptMailing]: boolean;
};

export type PaymentSectionValues = PaymentFormValues & {
  paymentMethodNonce: string;
  deviceData: string;
};

type CustomerDetailsValues = {
  [CustomerDetail.Title]: string;
  [CustomerDetail.FirstName]: string;
  [CustomerDetail.Surname]: string;
  [CustomerDetail.Email]: string;
  [CustomerDetail.Phone]: string;
  [CustomerDetail.DOB]?: string;
  [CheckboxName.AcceptTerms]?: boolean;
  [CheckboxName.AcceptPrivacyPolicy]?: boolean;
};

export type AddressValue = {
  [CheckoutFields.AddressLine1]: string;
  [CheckoutFields.AddressLine2]: string | null;
  [CheckoutFields.AddressLine3]: string | null;
  [CheckoutFields.AddressCity]: string;
  [CheckoutFields.AddressCounty]: string | null;
  [CheckoutFields.AddressMonthsAtAddress]?: string | null;
  [CheckoutFields.AddressPostcode]: string;
  [CheckoutFields.AddressYearsAtAddress]?: string | null;
  [CheckoutFields.AddressYearMovedIn]?: string | null;
  [CheckoutFields.AddressMonthMovedIn]?: string | null;
};

export type AddressHistoryValue = AddressValue & {
  [AddressHistoryAddress.Current]: boolean;
  [AddressHistoryAddress.Id]: string;
  [AddressHistoryAddress.Months]: "" | "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11";
  [AddressHistoryAddress.Years]: "" | "0" | "1" | "2" | "3";
  [AddressHistoryAddress.MonthMovedIn]: string;
  [AddressHistoryAddress.YearMovedIn]: string;
};

export type AddressHistoryFormValues = {
  [CheckoutFields.AddressHistory]: AddressHistoryValue[];
};

export type InstallationAddressValues = AddressValue & {
  [Address.Note]?: string;
};

export type SubscriptionCheckoutValues = CustomerDetailsValues & {
  [CheckoutFields.CheckoutInstallerId]?: null | string;
  [CheckoutFields.InstallationPrice]?: number;
  [CheckoutFields.JobLength]?: number;
  [CheckoutFields.OversellSlotAvailable]?: boolean;
  [CheckoutFields.SelectedDate]: string;
  [CustomerDetail.AddressHistory]: AddressHistoryValue[];
  [CustomerDetail.FinancialInfo]: FinancialInfoValues;
  [CustomerDetail.InstallationAddress]: InstallationAddressValues;
};

export type SubscriptionCheckoutHSValues = Omit<CustomerDetailsValues, CheckboxName.AcceptPrivacyPolicy> & {
  [CheckoutFields.SelectedDate]: string;
  [CustomerDetail.InstallationAddress]: InstallationAddressValues;
  [CustomerDetail.AddressHistory]: AddressValue[];
  [CustomerDetail.FinancialInfo]: FinancialInfoValuesHS;
};

export type StateData = {
  stage: string;
  values: ProductCheckoutContext & RepairCheckoutContext;
};

type FormValues =
  | Values
  | CheckoutFormValues
  | PaymentSectionValues
  | ConfirmDirectDebitFormValues
  | CustomerDetailsValues
  | SolarInstallationAddressValues
  | SubscriptionCheckoutHSValues
  | SubscriptionCheckoutValues;

export type CheckoutSectionProps = {
  basketFallbackData?: BasketData | SolarBasketData;
  checkoutUrl: string;
  contentTestId?: string;
  editButtonTheme?: EditButtonTheme;
  fallbackData:
    | StateData
    | SolarEnquiryMachineData
    | {
        stage: string;
        values: SubscriptionCheckoutValues;
      };
  formValues?: CheckoutFormValues;
  initialBasketData?: BasketData;
  installationDaysFetcher?: (date: string) => Promise<any>;
  installerUrl?: string;
  i18nNamespace: string;
  isLoading?: boolean;
  isPriceVisible?: boolean;
  isSaveQuoteDrawerOpen?: boolean;
  jobLength?: number;
  nextAvailableDate?: string;
  onEdit?: () => void;
  onSubmit?: (() => Promise<void> | void) | ((values: FormValues) => Promise<void | boolean> | void);
  postcode?: string;
  productType?: Products;
  screeningId?: string;
  screeningQuery?: ScreeningQuery;
  scrollToPosition: ({ sectionHeight }: { sectionHeight: number }) => void;
  shouldShowEdit?: boolean;
  showCalendarInfo?: boolean;
  isFullTextDisplayed?: boolean;
  showInfoPanel?: boolean;
  showSubtitle?: boolean;
  showTimePreference?: boolean;
  variant?: ScreenerVariant;
  setIsSaveQuoteDrawerOpen?: (isOpen: boolean) => void;
  setIsOversellSlotAvailable?: (isOversellSlotAvailable: boolean) => void;
  submitButtonText?: string;
  theme?: CalendarTheme;
  isFloatingOrderSummaryActive?: boolean;
};

export type TimePreference = "am" | "pm" | "none";
export type Values = {
  [CheckoutFields.SelectedDate]: string | null;
  [CheckoutFields.TimePreference]?: TimePreference;
  [CheckoutFields.JobLength]?: number;
  [CheckoutFields.InstallationPrice]?: number;
  [CheckoutFields.OversellSlotAvailable]: boolean;
};

export type PreOrderParamType = CheckoutFormValues & {
  [CheckoutFields.SelectedDate]: string[];
  [CheckoutFields.InstallationPrice]: number;
  [CheckoutFields.CheckoutInstallerId]: string;
};

export type BasketData = {
  meta?: BasketMeta | null;
  basket: Basket | null;
};

// TODO this is temporary, we need to update the order type
type LineItem = {
  id: string;
  basketId: string;
  units: number;
  priceInPence: number;
  name: string;
  boxtPackNumber: string;
  totalInstallerFeesNet: number;
  totalInstallerFeesVat: number;
  products: any[];
  supplierId: string;
  supplierEmailOrName: string;
};

type InstallationAddress = {
  id: string;
  addressLine1: string;
  addressLine2: any;
  addressLine3: any;
  city: string;
  county: string;
  postcode: string;
  buildingNumber: any;
  addressableId: string;
  addressableType: string;
  createdAt: string;
  updatedAt: string;
  latitude: string;
  longitude: string;
};
export type OrderData = {
  acquisitionChannel: string;
  addToMailingList: boolean;
  financeApplicationId: string | null;
  additionalPaymentUrl: string;
  installationAddress: InstallationAddress;
  amountInPence: number;
  approvedWithoutReview: boolean;
  supplierEmailOrName: string;
  basket: Basket;
  beyondEconomicRepairNumber: any;
  beyondEconomicRepairType: any;
  cancellable: boolean;
  companyId: string | null;
  companyName: string | null;
  customerType: string;
  customer: Customer;
  dates: string[];
  deliveryDate: string;
  gasSafetyRecord: boolean;
  homeCoverUrl: string;
  homeserveState: any;
  id: string;
  installationDayDiscount: number;
  installationJobCancellable: string | null;
  installationJobId: string | null;
  installationJobInstallerFullName: string | null;
  installationJobInstallerId: string | null;
  installationJobInstallerPostcode: string | null;
  installationJobState: string | null;
  installationSkillsRequired: string[];
  jobAudit: string | null;
  jobType: string;
  legacy: boolean;
  lineItems: LineItem[];
  meta: Record<string, any>;
  notes: string | null;
  ozevApproved: string | null;
  paymentMethod: string;
  paymentRefs: string | null[];
  postcode: string;
  productType: string;
  quoteId: string | null;
  quoteNumber: string | null;
  region: string | null;
  riskAssessments: string | null;
  screeningHistory: Record<string, any>;
  sentPhotosChaserEmail: boolean;
  shortId: string;
  smartHome: boolean;
  state: string;
  subJobs: any;
  subscription: string | null;
  supplierConfirmed: string | null;
  supplierConfirmedAt: string | null;
  supplierName: string | null;
  supplierId: string;
  warranty: number;
  calloutJobs: any[];
  outstandingAmountInPence: number;
  partialPayments: boolean;
  satNoteApproved: boolean;
  satNoteApprovedAt: string | null;
  screeningId: string;
};
export type BasketMeta = {
  promoCode: PromoCode;
};

export type PromoCode = {
  id: string | null;
  name: string | null;
  pillDescription?: string | null;
  code: string | null;
  active: boolean | null;
  type: string | null;
  value: number | null;
  productCategories: ProductCategory[] | null;
  freePackageId: string | null;
};

type ProductCategory = {
  id: string;
  displayName: string;
  value: string;
  filterable: boolean;
  major: boolean;
};

export enum Promo {
  Fixed = "FixedAmount",
  Free = "FreePackage",
}

export enum PromoCodeStatuses {
  Entered = "coupon_entered",
  Applied = "coupon_applied",
  Denied = "coupon_denied",
}

export type DirectDebitFormValues = {
  [DirectDebit.AccountHolderName]?: string;
  [DirectDebit.AccountNumber]?: string;
  [DirectDebit.BranchCode]?: string;
  [DirectDebit.DayOfMonth]?: number | string; // due to -1 being a valid value
  [DirectDebitCheckbox.SameBillingAddress]?: boolean;
  [DirectDebitCheckbox.MultipleSignatures]?: boolean;
  [BillingAddress.Line1]?: string;
  [BillingAddress.Line2]?: string;
  [BillingAddress.Line3]?: string;
  [BillingAddress.City]?: string;
  [BillingAddress.County]?: string;
  [BillingAddress.Postcode]?: string;
};

export type ConfirmDirectDebitFormValues = {
  [CheckboxName.AcceptTerms]: boolean;
  [CheckboxName.AcceptMailing]: boolean;
};

export type EditButtonTheme = "jade" | "coral";

export type FinancialInfoValues = {
  [CheckoutFields.AnnualIncome]?: string;
  [CheckoutFields.EmploymentStatus]:
    | ""
    | "employed"
    | "self_employed"
    | "part_time_employed"
    | "student"
    | "unemployed"
    | "retired";
  [CheckoutFields.TakeHomePay]?: string;
  [CheckoutFields.HouseholdIncome]?: string;
  [CheckoutFields.MortgagePayments]?: string;
  [CheckoutFields.OtherExpenses]?: string;
  [CheckoutFields.NumberOfAdults]?: "" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10";
  [CheckoutFields.NumberOfChildren]?: "" | "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10";
  [CheckoutFields.FinancialSituationChanged]?: "" | FinancialSituationChanged.Yes | FinancialSituationChanged.No;
};
export type FinancialInfoValuesHS = {
  [CheckoutFields.EmploymentStatus]:
    | ""
    | "employed"
    | "self_employed"
    | "part_time_employed"
    | "student"
    | "unemployed"
    | "retired";
  [CheckoutFields.AnnualIncome]: string;
  [CheckboxName.AcceptPrivacyPolicy]: boolean;
  [CheckoutFields.FinancialSituationChanged]?: "" | FinancialSituationChanged.Yes | FinancialSituationChanged.No;
};

export enum CreditCheckSections {
  INSTALLATION_DETAILS = "installation_details",
  PERSONAL_DETAILS = "personal_details",
  ADDRESSES = "addresses",
  FINANCIAL_INFO = "financial_information",
  PRE_CONTRACT_DOCUMENTS = "pre_contract_documents",
  HIRE_AGREEMENT = "hire_agreement",
  PAYMENT_DETAILS = "payment_details",
  CONFIRMATION = "confirmation",
  RESIGN_AGREEMENT = "resign_agreement",
  RESIGN_OVERVIEW = "resign_overview",
}

export enum CreditCheckDecisions {
  ACCEPTED = "accepted",
  DECLINED = "declined",
  REFERRED = "referred",
}

export type CreditCheckType = {
  amountInPence: number;
  errored: boolean;
  decision: null | CreditCheckDecisions;
  hireAgreementUrl: null | string;
  id: string;
  locked: boolean;
  orderId: string | null;
  section: CreditCheckSections;
  termInMonths: number;
};

export type CreditCheckTypeHS = {
  amountInPence: number;
  decision: null | CreditCheckDecisions;
  errored: boolean;
  pcciUrl: null | string;
  pcciSignedAt: null | string;
  contractAgreementSignedAt: null | string;
  contractAgreementUrl: null | string;
  keyFactsSignedAt: null | string;
  keyFactsUrl: null | string;
  id: string;
  locked: boolean;
  orderId: string | null;
  section: CreditCheckSections;
  serviceScopeUrl: null | string;
  serviceScopeSignedAt: null | string;
  termInMonths: number;
};
