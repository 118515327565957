import type { FocusEvent } from "react";
import useTranslation from "next-translate/useTranslation";
import { isEmpty } from "lodash-es";
import { Paragraph, Spacing } from "@boxt/design-system";

import currencyFormatter, { penceToPounds } from "@Helpers/currency";
import { currencyFormat } from "@Helpers/inputFormatters";
import type { DepositGuide, FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import Deposit from "@Components/FinanceCalculator/components/DepositSlider";
import MonthlyPayments from "@Components/FinanceCalculator/components/MonthlyPayments";
import type { ButterFinanceCalculatorTabFields } from "@Components/pages/finance/types";

import { convertCurrencyToPence, DEFAULT_MIN_PURCHASE_PRICE } from "../helpers";

import LabelledInput from "./LabelledInput";

export type Props = {
  content: ButterFinanceCalculatorTabFields;
  depositValue: number;
  priceOfProduct: number;
  onPriceChange: (value: number) => void;
  onPriceBlur: (value: number) => void;
  selectedPlanId: string | undefined;
  onMonthlyPaymentChange: (id: string) => void;
  hasFixedAmount: boolean;
  hasHelperText: boolean;
  zeroPercentFinanceThirtySix: boolean;
  zeroPercentFinanceFortyEight: boolean;
  setDepositAmount: (value: number) => void;
  purchasePrice: number;
  includeScrollDelay: boolean;
  financePlans: FinancePlan[] | undefined;
  depositGuide: DepositGuide | undefined;
};

const LeftColumnContent = ({
  content,
  depositValue,
  onMonthlyPaymentChange,
  onPriceBlur,
  onPriceChange,
  hasFixedAmount,
  hasHelperText,
  priceOfProduct,
  selectedPlanId,
  zeroPercentFinanceThirtySix,
  zeroPercentFinanceFortyEight,
  setDepositAmount,
  purchasePrice,
  includeScrollDelay,
  financePlans,
  depositGuide,
}: Props) => {
  const { t } = useTranslation("common");

  const { calc_tab_left_column_title: title, calc_tab_left_column_minimum_value_allowed } = content;
  const minPurchasePrice = isEmpty(calc_tab_left_column_minimum_value_allowed)
    ? DEFAULT_MIN_PURCHASE_PRICE
    : calc_tab_left_column_minimum_value_allowed;

  const handleOnChange = (value: string) => {
    onPriceChange(convertCurrencyToPence(value));
  };

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;

    onPriceBlur(convertCurrencyToPence(value));
  };

  const activePlan = financePlans?.find((plan) => plan.id === selectedPlanId);

  return (
    <>
      <LabelledInput
        disabled={hasFixedAmount}
        format={currencyFormat}
        labelText={title || t("calculator.total-price")}
        name="product-price"
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        value={String(Math.floor(penceToPounds(priceOfProduct)))}
      />
      <Spacing mb={4}>
        {!hasFixedAmount && (
          <Paragraph boxtTheme="slateLight" size="small" weight="regular">
            {t("calculator.product-price-explainer", {
              minPrice: currencyFormatter(minPurchasePrice),
            })}
          </Paragraph>
        )}
      </Spacing>
      {Number(financePlans?.length) > 1 && (
        <MonthlyPayments
          data-testid="monthly-payments"
          financePlans={financePlans}
          onMonthlyPaymentChange={onMonthlyPaymentChange}
          selectedPlanId={selectedPlanId}
          zeroPercentFinanceFortyEight={zeroPercentFinanceFortyEight}
          zeroPercentFinanceThirtySix={zeroPercentFinanceThirtySix}
        />
      )}
      <Deposit
        minDepositInPercentage={depositGuide?.minDepositPercentage}
        {...(activePlan && {
          depositGuideMessage: t("calculator.deposit-guide-message", {
            percentage: activePlan?.minDepositPercentage,
          }),
        })}
        depositValueInPence={depositValue}
        includeScrollDelay={includeScrollDelay}
        purchasePrice={purchasePrice}
        setDepositAmount={setDepositAmount}
        stepNumber={Number(financePlans?.length) > 1 ? 2 : 1}
      />
      {hasHelperText && (
        <Paragraph
          boxtTheme="slateLight"
          data-testid="deposit-helper-text"
          size="small"
          top={{ lg: 7, md: 8, sm: 9 }}
          weight="regular"
        >
          {t("calculator.slider-deposit-amount-explainer")}
        </Paragraph>
      )}
    </>
  );
};

export default LeftColumnContent;
