import { useState } from "react";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import useScreenerStatus from "@ScreenerSetup/hooks/useScreenerStatus";
import { isEmpty } from "lodash-es";
import { parseCookies } from "nookies";

import { SCREENER_COOKIE_KEY } from "@Constants/screenerCookieKey";
import { SCREENING_URLS } from "@Collections/routes";
import { ScreenerVariant } from "@Pages/api/_next/screeners/types";

const screenersToCheck = [
  { variant: ScreenerVariant.BoilerInstallation },
  { variant: ScreenerVariant.BoilerSubscription },
  { variant: ScreenerVariant.Solar },
  { variant: ScreenerVariant.BatteryOnly },
  { variant: ScreenerVariant.HeatPump },
  { variant: ScreenerVariant.AirCondition },
];

const finishQuoteImages = {
  [ScreenerVariant.Solar]: "https://boxt-bcms.imgix.net/R9X7gPOUTTWcHhxZzDO9",
  [ScreenerVariant.BatteryOnly]: "https://boxt-bcms.imgix.net/qXcX1oL1TbdJXWGSZb7A",
  [ScreenerVariant.BoilerInstallation]: "https://boxt-bcms.imgix.net/jMBx2PJXThCX0u2fVpp0",
  [ScreenerVariant.HeatPump]: "https://boxt-bcms.imgix.net/q6TKvViCTSSMPzcfg9Ve",
  [ScreenerVariant.BoilerSubscription]: "https://boxt-bcms.imgix.net/A3THHdKnT8SIgdFtf0yc",
  [ScreenerVariant.AirCondition]: "https://boxt-bcms.imgix.net/o4UUWf4TyOZZsv9A9WaW",
};

const useQuotes = () => {
  const { t } = useTranslation("list/index");

  const finishQuoteTitles = {
    [ScreenerVariant.Solar]: t("title-hero.solar-title"),
    [ScreenerVariant.BatteryOnly]: t("title-hero.battery-only-title"),
    [ScreenerVariant.BoilerInstallation]: t("title-hero.boiler-title"),
    [ScreenerVariant.HeatPump]: t("title-hero.heat-pump-title"),
    [ScreenerVariant.BoilerSubscription]: t("title-hero.boiler-subscription-title"),
    [ScreenerVariant.AirCondition]: t("title-hero.air-condition-title"),
  };

  const router = useRouter();

  const [isFinishQuoteLoading, setIsFinishQuoteLoading] = useState(false);
  const [isNewQuoteBottomSheetOpen, setIsNewQuoteBottomSheetOpen] = useState(false);
  const [isFinishQuoteBottomSheetOpen, setIsFinishQuoteBottomSheetOpen] = useState(false);

  const screenerStates = useScreenerStatus(screenersToCheck);

  const existingScreeners =
    (screenersToCheck
      ?.map(({ variant }) => {
        const { isScreenerComplete, isScreenerStarted } = screenerStates[variant] || {};

        if (isScreenerComplete) {
          const cookies = parseCookies();
          const screeningId = cookies[`${SCREENER_COOKIE_KEY}:${variant}`];
          return {
            url: SCREENING_URLS[variant]?.complete.replace("[screeningId]", screeningId),
            variant,
          };
        } else if (isScreenerStarted) {
          return {
            url: SCREENING_URLS[variant]?.start,
            variant,
          };
        }
        return null;
      })
      .filter(Boolean) as { variant: ScreenerVariant; url: string }[]) || [];

  const handleFinishQuoteClick = () => {
    setIsFinishQuoteLoading(true);
    if (Number(existingScreeners?.length) > 1) {
      setIsFinishQuoteBottomSheetOpen(true);
      setIsFinishQuoteLoading(false);
    } else if (existingScreeners?.length === 1) {
      router?.push({
        pathname: existingScreeners[0]?.url,
      });
    }
  };

  const hasExistingScreeners = !isEmpty(existingScreeners);

  const generatedTiles =
    existingScreeners?.map((screener, i) => {
      const { variant, url } = screener;
      return {
        item: {
          image: finishQuoteImages[variant],
          key: i,
          link: url,
          meta: { id: i },
          slug: `finish-quote-${variant}`,
          title: finishQuoteTitles[variant],
        },
      };
    }) || [];

  const newQuoteTile = {
    item: {
      image: "https://boxt-bcms.imgix.net/lS53JZBxQyOLXnYD2pTw",
      meta: { id: generatedTiles.length + 1 },
      onClick: () => {
        setIsFinishQuoteBottomSheetOpen(false);
        setIsNewQuoteBottomSheetOpen(true);
      },
      slug: "start-new-quote",
      title: t("title-hero.new-quote"),
    },
  };

  const finishQuoteTiles = [...generatedTiles, newQuoteTile];

  return {
    finishQuoteTiles,
    handleFinishQuoteClick,
    hasExistingScreeners,
    isFinishQuoteBottomSheetOpen,
    isFinishQuoteLoading,
    isLoading: false, // TODO: remove this in BR-3524
    isNewQuoteBottomSheetOpen,
    setIsFinishQuoteBottomSheetOpen,
    setIsNewQuoteBottomSheetOpen,
  };
};

export default useQuotes;
