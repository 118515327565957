import { useMemo } from "react";
import { last } from "lodash-es";

import type { Products } from "@Collections/products";
import type { FinancePlan } from "@Components/Checkout/stages/components/PaymentMethods/Finance/types";
import useFetchFinancePlans from "@Hooks/useFetchFinancePlans";
import useFetchSenaFinancePlans from "@Hooks/useFetchSenaFinancePlans";
import useHasFinance from "@Hooks/useHasFinance";

type Props = {
  productType: Products | undefined;
  selectedPlanId?: string | null;
  deposit?: number;
  purchasePrice?: number;
  isBnpl?: boolean;
  isPayMonthly?: boolean;
  isRetailFinance?: boolean;
  isBnplAvailable?: boolean | null;
  isBnplActive?: boolean;
  zeroPercentFinanceThirtySix?: boolean | null;
  zeroPercentFinanceFortyEight?: boolean | null;
  hasSenaPlans?: boolean;
};

type ReturnType = {
  activePlan: FinancePlan | null | undefined;
  isCalculating: boolean;
  isLoading: boolean;
  financePlans: FinancePlan[];
  hasError: Boolean;
  hasBnplPlans: Boolean;
};

const useFinance = ({
  productType,
  deposit,
  selectedPlanId,
  purchasePrice,
  isBnpl = true,
  isPayMonthly = true,
  isRetailFinance = false,
  isBnplAvailable,
  isBnplActive,
  zeroPercentFinanceThirtySix = false,
  zeroPercentFinanceFortyEight = false,
  hasSenaPlans = false,
}: Props): ReturnType => {
  const hasPurchasePrice = Number(purchasePrice) > 0;
  const { hasFinance } = useHasFinance({ productType });

  const {
    isLoading: isFetchingFinancePlans,
    isValidating: isCalculating,
    financePlans,
    hasError: hasFinancePlanError,
  } = useFetchFinancePlans({
    deposit,
    isBnpl,
    isPayMonthly,
    productType,
    purchasePrice,
  });
  const {
    isLoading: isSenaPlansFetching,
    isValidating: isSenaPlansValidating,
    financePlans: senaFinancePlans,
    hasError: hasSenaFinancePlanError,
  } = useFetchSenaFinancePlans({
    deposit,
    hasSenaPlans,
    productType,
    purchasePrice,
  });

  const decoratedFinancePlans = useMemo(() => {
    if (isRetailFinance) return senaFinancePlans;
    if (!hasSenaPlans) return financePlans;
    const zeroAprFinancePlans = financePlans.filter(({ apr }) => apr === 0);
    return [...zeroAprFinancePlans, ...senaFinancePlans];
  }, [financePlans, hasSenaPlans, isRetailFinance, senaFinancePlans]);

  const activePlan = useMemo(() => {
    if (!decoratedFinancePlans && !hasPurchasePrice) return null;

    if (selectedPlanId) {
      return decoratedFinancePlans.find((plan) => plan.id === selectedPlanId);
    }

    return last(decoratedFinancePlans);
  }, [decoratedFinancePlans, hasPurchasePrice, selectedPlanId]);

  const isLoading = hasFinance && isFetchingFinancePlans && isSenaPlansFetching;

  const hasBnplPlans = decoratedFinancePlans?.some((plan) => plan?.deferredPeriod > 0);

  const isZeroPercentFinance = zeroPercentFinanceThirtySix || zeroPercentFinanceFortyEight;

  const filterPlansForZeroPercentFinance = useMemo(() => {
    if (!isZeroPercentFinance) {
      return decoratedFinancePlans.filter(({ apr }) => apr !== 0);
    }

    return decoratedFinancePlans.filter(({ apr, term }) => {
      if (apr !== 0) return true;
      if (term <= 36) {
        return zeroPercentFinanceThirtySix;
      }

      return zeroPercentFinanceFortyEight;
    });
  }, [isZeroPercentFinance, decoratedFinancePlans, zeroPercentFinanceFortyEight, zeroPercentFinanceThirtySix]);

  const availablePlans = useMemo(
    () =>
      isBnplActive && isBnplAvailable
        ? filterPlansForZeroPercentFinance.filter((plan) => plan.deferredPeriod > 0)
        : filterPlansForZeroPercentFinance.filter((plan) => plan.deferredPeriod === 0),
    [filterPlansForZeroPercentFinance, isBnplActive, isBnplAvailable],
  );

  return {
    activePlan,
    financePlans: availablePlans,
    hasBnplPlans,
    hasError: hasFinancePlanError || hasSenaFinancePlanError,
    isCalculating: isCalculating || isSenaPlansValidating,
    isLoading,
  };
};

export default useFinance;
