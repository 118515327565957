import getConfig from "next/config";

export const CLIENT_NAME = getConfig()?.publicRuntimeConfig.CLIENT_NAME?.toUpperCase();

export const CLIENT_COMPANY_NAME = getConfig()?.publicRuntimeConfig.CLIENT_COMPANY_NAME;

export const CLIENT_FCA_REGISTER_NUMBER = getConfig()?.publicRuntimeConfig.CLIENT_FCA_REGISTER_NUMBER;

export const CLIENT_REGISTERED_COMPANY_NUMBER = getConfig()?.publicRuntimeConfig.CLIENT_REGISTERED_COMPANY_NUMBER;

export const CLIENT_VAT_NUMBER = getConfig()?.publicRuntimeConfig.CLIENT_VAT_NUMBER;

export const CLIENT_REGISTERED_COMPANY_ADDRESS = getConfig()?.publicRuntimeConfig.CLIENT_REGISTERED_COMPANY_ADDRESS;

export const CUSTOMER_SERVICE_PHONE = getConfig()?.publicRuntimeConfig.CUSTOMER_SERVICE_PHONE;

export const RENAWABLES_CUSTOMER_SERVICE_EMAIL = getConfig().publicRuntimeConfig.RENAWABLES_CUSTOMER_SERVICE_EMAIL;

export const CUSTOMER_SERVICE_EMAIL = getConfig().publicRuntimeConfig.CUSTOMER_SERVICE_EMAIL;

export const SERVICING_EMAIL = getConfig().publicRuntimeConfig.SERVICING_EMAIL;
